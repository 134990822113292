import React from "react";
import { Layout } from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";
import { SEO } from "../components/SEO/SEO";

export const FourOhFourPage = () => {
  return (
    <Layout>
      <SEO title="Page not found (404)" />
      <Hero
        heading="Page not found (404)"
        lead="Sorry, that page could not be found"
      />
    </Layout>
  );
};

export default FourOhFourPage;
